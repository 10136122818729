import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import CreatePost from './components/CreatePost';
import PostDetail from './components/PostDetail';
import PostList from './components/PostList';
import Login from './components/login';
import Forgotpassword from './components/ForgotPassword';
import About from './pages/about';
import InsertAbout from './pages/InsertAbout';

import Contact from './pages/contact';
import CreateAccount from './components/CreateAccount'; 
import UpdateAccount from './components/UpdateAccount';
import Profile from './components/Profile';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Header isAuthenticated={isAuthenticated} onLogout={handleLogout} />
        <Routes>
          <Route path="/" element={<PostList />} />
          <Route path="/about" element={<About />} />
          <Route path="/insert-about" element={<InsertAbout />} />
          <Route path="/forgot-password" element={<Forgotpassword/>} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/profile" element={isAuthenticated ? <Profile /> : <Login onLogin={handleLogin} />} />
          <Route path="/create-post" element={isAuthenticated ? <CreatePost /> : <Login onLogin={handleLogin} />} />
          <Route path="/updateaccount" element={isAuthenticated ? <UpdateAccount /> : <Login onLogin={handleLogin} />} />
          <Route path="/post/:id" element={<PostDetail />} />
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route path="/create-account" element={<CreateAccount />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

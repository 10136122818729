import React, { useState } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { API_PATH } from './config'; // Import API_PATH from config.js

const InsertAbout = () => {
  const [newContent, setNewContent] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);

  const handleInsert = async (e) => {
    e.preventDefault();  // Prevent the default form submission behavior

    try {
      const response = await axios.post(`${API_PATH}/update-about.php`, 
        { content: newContent }, 
        { withCredentials: true } // Ensure credentials are included
      );

      if (response.data.status === 'success') {
        setMessage('Content inserted successfully!');
        setNewContent('');  // Clear the input after success
        setError(null);  // Clear any previous errors
      } else {
        setMessage('Failed to insert content');
        setError(null);  // Clear any previous errors
      }
    } catch (error) {
      console.error('Failed to insert About Us content:', error);
      setError(error);
      setMessage('Failed to insert content');
    }
  };

  return (
    <div className="container">
      <h1>Insert About Us Content</h1>
      <Form onSubmit={handleInsert}>
        <Form.Group>
          <Form.Label>New About Us Content</Form.Label>
          <Form.Control 
            as="textarea" 
            rows={5} 
            value={newContent} 
            onChange={(e) => setNewContent(e.target.value)} 
          />
        </Form.Group>
        <Button type="submit" variant="success">Insert Content</Button>
      </Form>
      {message && <p>{message}</p>}
      {error && <p>Error: {error.message}</p>}
    </div>
  );
};

export default InsertAbout;

import { useState } from 'react';
import axios from 'axios';


//import { ThemeProvider } from '@mui/material/styles';
//import { CssBaseline, StyledEngineProvider } from '@mui/material';
//import Routes from './routes';
//import themes from './themes';
//import NavigationScroll from './layout/NavigationScroll';
//import { BrowserRouter as Router } from 'react-router-dom';


function Contact() {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  const handleSubmit = () => {
    if (name.length === 0) {
      alert("Name has left Blank!");
    } else if (mobile.length === 0) {
      alert("Mobile has left Blank!");
    } else if (email.length === 0) {
      alert("Email has left Blank!");
    } else {
      const url = 'https://laravelapinew.aihelth.com/api/submit-form';
  
      const formData = {
        name: name,
        mobile: mobile,
        email: email
      };
  
      const jsonData = JSON.stringify(formData);
  
      axios.post(url, jsonData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        const data = response.data;
        if (data.status) {
          alert(data.message || "Record inserted successfully.");
        } else {
          alert("Failed to insert record.");
        }
      })
      .catch(error => alert(error));
    }
  }

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUser(null);
  }

  return (
    <>
{/* <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes}>
          <CssBaseline />
          <NavigationScroll>
            <Routes />
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>  */}

    
    
      <div className="container">
        <div className="vh-100 gradient-custom">
          <div className="mask d-flex align-items-center h-100 gradient-custom-3">
            <div className="container h-100">
              <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                  <div className="card">
                    <div className="card-body p-5">
                      <h3 className="text-center mb-5">React JS Submit Form with PHP MySQL</h3>
                      {!isLoggedIn ? (
                        <form>
                          <div className="form-outline mb-4">
                            <label className="form-label" htmlFor="name">Your Name</label>
                            <input type="text" className="form-control form-control-lg" name="name" id="name" value={name} onChange={(e) => setName(e.target.value)} />
                          </div>
                          <div className="form-outline mb-4">
                            <label className="form-label" htmlFor="mobile">Your Mobile</label>
                            <input type="text" className="form-control form-control-lg" name="mobile" id="mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                          </div>
                          <div className="form-outline mb-4">
                            <label className="form-label" htmlFor="email">Your Email</label>
                            <input type="email" className="form-control form-control-lg" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                          </div>
                          <div className="form-check d-flex justify-content-center mb-5">
                            <input className="form-check-input me-2" type="checkbox" value="" id="form2Example3cg" />
                            <label className="form-check-label" htmlFor="form2Example3g">
                              I agree all statements in <a href="#!" className="text-body"><u>Terms of service</u></a>
                            </label>
                          </div>
                          <div className="d-flex justify-content-center">
                            <input type="button" className="btn btn-success btn-block btn-lg gradient-custom-4 text-body" name="submit" id="submit" value="Submit" onClick={handleSubmit} />
                          </div>
                        </form>
                      ) : (
                        <div>
                          {/* Content for logged-in users */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;

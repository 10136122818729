import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ROOT_URL } from '../../src/components/env'; // Import ROOT_URL from the appropriate file

export const API_PATH = `${ROOT_URL}/server/api`;

function UpdateAccount() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        // Fetch the current user details and set the form fields
        const fetchUserDetails = async () => {
            try {
                const response = await axios.get(`${API_PATH}/getuserdetail.php`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}` // or use session storage
                    }
                });
        
                if (response.data.status === 'success') {
                    const { username, email } = response.data.data;
                    setUsername(username);
                    setEmail(email);
                } else {
                    setError(response.data.message);
                }
            } catch (error) {
                console.error('Failed to fetch user details:', error.response ? error.response.data : error.message);
                setError('Failed to load user details. Please try again later.');
            }
        };

        fetchUserDetails();
    }, []);

    const validateForm = () => {
        if (!username.trim() || !email.trim()) {
            setError("Please fill in all fields.");
            return false;
        }
        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        if (!validateForm()) return;

        setIsLoading(true);

        try {
            const response = await axios.post(`${API_PATH}/update-account.php`, {
                username,
                password, // Include the password only if it's being updated
                email
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}` // or use session storage
                }
            });

            if (response.data.status === 'success') {
                navigate('/profile'); // Redirect to the profile page after successful update
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            console.error('Failed to update account:', error);
            setError('Failed to update account. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container mt-4">
            <h2>Update Account Details</h2>
            {error && <div className="alert alert-danger" role="alert">{error}</div>}
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="username" className="form-label">Username</label>
                    <input
                        type="text"
                        className="form-control"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password (leave blank to keep current password)</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                    {isLoading ? (
                        <span><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Updating account...</span>
                    ) : 'Update Account'}
                </button>
            </form>
        </div>
    );
}

export default UpdateAccount;

import React, { useState } from 'react';
import axios from 'axios';
import { API_PATH } from '../constants/Constants';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setMessage(''); // Clear any previous message

        try {
            const response = await axios.post(`${API_PATH}/forgot-password.php`, { email });
            setMessage(response.data.message);
        } catch (error) {
            console.error("Error sending reset email:", error); // Log any error for debugging
            setMessage('Failed to send reset email. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container mt-4">
            <h2>Forgot Password</h2>
            {message && <div className="alert alert-info" role="alert">{message}</div>}
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                    {isLoading ? 'Sending...' : 'Send Reset Link'}
                </button>
            </form>
        </div>
    );
}

export default ForgotPassword;

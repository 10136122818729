import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Header = ({ isAuthenticated, onLogout }) => {
    const navigate = useNavigate();

    const handleLogoutClick = () => {
        onLogout();
        navigate('/');
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">Blog </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link" to="/">Home</Link>
                        </li>
                      
                        {/* <li className="nav-item">
                            <Link className="nav-link" to="/create-post">Create Post</Link>
                        </li> */}
                        {!isAuthenticated ? (
                            <>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/login">Login</Link>
                                </li>
                                <li className="nav-item">
                               <Link className="nav-link" to="/about">About Us</Link>
                              </li>
                              <li className="nav-item">
                               <Link className="nav-link" to="/insert-about">Insert About Us</Link>
                              </li>
                              <li className="nav-item">
                               <Link className="nav-link" to="/contact">Contact Us</Link>
                              </li>
                               
                                <li className="nav-item">
                                    <Link className="nav-link" to="/create-account">Create Account</Link> {/* Add Create Account link */}
                                </li>
                            </>
                        ) : (
                            <>
                             <li className="nav-item">
                               <Link className="nav-link" to="/updateaccount">Update Account</Link>
                              </li>
                             <li className="nav-item">
                               <Link className="nav-link" to="/contact">Contact Us</Link>
                              </li>
                            <li className="nav-item">
                                <button className="btn btn-link nav-link" onClick={handleLogoutClick}>Logout</button>
                            </li>
                            </>
                            
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Header;
